<template>
  <client-list :clients="clients"></client-list>
</template>

<script>
import useClient from '@/use/useClient.js'
import ClientList from '@/components/ClientList.vue'

export default {
  components: {
    ClientList
  },
  setup() {
    const { clients } = useClient()
    return {
      clients
    }
  }
}
</script>
